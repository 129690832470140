// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import YearTabs from '../components/YearTabs';
import MonthTabs from '../components/MonthTabs';
import ExpensesList from '../components/ExpensesList';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';

const Home = () => {
  const { logout, isAuthenticated } = useAuth(); // Access logout and isAuthenticated from AuthContext
  const [selectedYear, setSelectedYear] = useState(() => {
    const currentYear = new Date().getFullYear().toString();
    return ['2023', '2024', '2025'].includes(currentYear) ? currentYear : '2024';
  });
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const currentMonth = new Date().getMonth() + 1; // getMonth() is zero-based
    return currentMonth;
  });
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch expenses whenever selectedYear or selectedMonth changes
  useEffect(() => {
    const fetchExpenses = async () => {
      setLoading(true);
      setError(null);
      setExpenses([]);

      try {
        const response = await axiosInstance.get(`get_expenses/${selectedMonth}/${selectedYear}/`);
        setExpenses(response.data);
      } catch (err) {
        console.error(err);
        setError('Failed to fetch expenses. Please try again later.');
        toast.error('Failed to fetch expenses. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchExpenses();
    }
  }, [selectedYear, selectedMonth, isAuthenticated]);

  const handleYearChange = (event, newValue) => {
    setSelectedYear(newValue);
  };

  const handleMonthChange = (event, newValue) => {
    setSelectedMonth(newValue);
  };

  return (<Box>
      {/* User Info and Logout Button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Welcome!</Typography>
        <Button variant="outlined" color="secondary" onClick={logout}>
          Logout
        </Button>
      </Box>

      {/* Year and Month Tabs */}
      <YearTabs selectedYear={selectedYear} handleYearChange={handleYearChange}/>
      <MonthTabs selectedMonth={selectedMonth} handleMonthChange={handleMonthChange}/>

      {/* Expenses List */}
      <ExpensesList expenses={expenses} loading={loading} error={error}/>
    </Box>);
};

export default Home;
