// src/components/ExpensesList.js
import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Alert, Box,
} from '@mui/material';

const ExpensesList = ({ expenses, loading, error }) => {
  if (loading) {
    return (<Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress/>
      </Box>);
  }

  if (error) {
    return (<Alert severity="error" sx={{ mt: 4 }}>
        {error}
      </Alert>);
  }

  if (expenses.length === 0) {
    return (<Typography variant="h6" align="center" sx={{ mt: 4 }}>
        No expenses found for this month.
      </Typography>);
  }

  return (<TableContainer component={Paper} sx={{ mt: 4 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Description</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Category</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Amount ($)</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Date</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {expenses.map((expense) => (<TableRow key={expense.id}>
              <TableCell>{expense.description}</TableCell>
              <TableCell align="right">{expense.category}</TableCell>
              <TableCell align="right">
                {parseFloat(expense.amount).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {new Date(expense.datetime).toLocaleDateString()}
              </TableCell>
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>);
};

export default ExpensesList;
